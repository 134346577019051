import { render, staticRenderFns } from "./EntitySearchWidget.vue?vue&type=template&id=13604c32&scoped=true&"
import script from "./EntitySearchWidget.vue?vue&type=script&lang=ts&"
export * from "./EntitySearchWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/entity-search/EntitySearchWidget.scss?vue&type=style&index=0&id=13604c32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13604c32",
  null
  
)

export default component.exports