var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReadyToDisplay),expression:"isReadyToDisplay"}],class:['entity-search-widget container-fluid d-flex flex-column row-gap-8', _vm.classList],attrs:{"id":"entity-search-widget"}},[_c('div',{staticClass:"container d-flex flex-column row-gap-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.configLoading && !_vm.hideFilters),expression:"!configLoading && !hideFilters"}],staticClass:"row mb-n2"},[_c('div',{class:['col-12', { 'mb-n3': _vm.children.filter(function (c) { return c.type !== 'Toggle'; }).length > 0 }]},[_c('entity-search-filter-widget',{attrs:{"children":_vm.children,"entity-type":_vm.entityType,"list-configs":_vm.listConfigs,"placeholder":_vm.placeholder,"range-configs":_vm.rangeConfigs,"search":_vm.search,"store-name":_vm.widgetStorePath,"toggle-configs":_vm.toggleConfigs},on:{"apply-entity-search":function($event){return _vm.loadPaginatedData(_vm.isAll ? 0 : 1)},"clear-entity-search":function($event){return _vm.loadPaginatedData(_vm.isAll ? 0 : 1)}}})],1)]),(!_vm.configLoading
        && (_vm.displaySelectorOptions.length > 1 && (_vm.isDesktop || _vm.isTablet)))?_c('div',{staticClass:"d-flex gap-6 flex-column flex-xl-row align-items-center justify-content-center"},[(_vm.displaySelectorOptions.length > 1 && (_vm.isDesktop || _vm.isTablet))?_c('segmented-controls-component',{class:[{'ml-auto': _vm.isDesktop}, {'w-100': _vm.isTablet}],attrs:{"elements":_vm.displaySelectorOptions,"is-full-width":true},on:{"on-click":_vm.switchTab}}):_vm._e()],1):_vm._e(),(!_vm.isLoading && !_vm.configLoading && !_vm.isCalendarView && _vm.localCount === 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-40 py-md-80 p-xl-6"},[_c('illustration-component',{attrs:{"illustration-type":_vm.IllustrationType.SEARCH_EMPTY,"message":("" + (_vm.$t('global-search-results-page.spelling'))),"title":("" + (_vm.$t('global-search-results-page.no-results-found')))}})],1)]):_vm._e(),(!_vm.isLoading && _vm.isCalendarView)?_c('div',{staticClass:"row row-gap-8"},[_c('div',{staticClass:"col-12"},[_c('sessions-calendar-component',{attrs:{"card-route":_vm.cardRoute,"display-featured-border":_vm.displayFeaturedBorder,"display-interested-badge":_vm.displayInterestedBadge,"display-live-badge":_vm.displayLiveBadge,"display-recommended-badge":_vm.displayRecommendedBadge,"display-speakers":_vm.displaySpeakers,"filter":_vm.appliedFilter},on:{"on-calendar-view-selected-date":_vm.onCalendarViewSelectedDate}})],1)]):_vm._e(),(!_vm.isLoading && _vm.paginatedItems && _vm.paginatedItems.length > 0 && !_vm.isCalendarView)?_c('div',{class:[
        'row',
        {'row-gap-8': _vm.cardType !== 'crmUser'},
        {'row-gap-4': _vm.cardType === 'crmUser' && _vm.isMobile} ]},_vm._l((_vm.paginatedItems
          .slice(0, (_vm.isDesktop ? (_vm.cardType === 'article' ? 6 : 8) : _vm.breakpoint.value === 'lg' ? 6 : 4))),function(card,idx){return _c('div',{key:card.uid,class:_vm.getClass(idx, _vm.paginatedItems.length)},[_c(_vm.WidgetCardMapping[_vm.cardType],_vm._b({tag:"component",attrs:{"card-route":_vm.cardRoute,"connection-modal-id":_vm.guuid.uuidv4(),"display-expiration-badge":_vm.displayExpirationBadge,"display-extra-properties":_vm.displayExtraProperties,"display-featured-border":_vm.displayFeaturedBorder,"display-hall":_vm.displayHall,"display-interested-badge":_vm.displayInterestedBadge,"display-live-badge":_vm.displayLiveBadge,"display-recommended-badge":_vm.displayRecommendedBadge,"display-speakers":_vm.displaySpeakers,"horizontal-display":_vm.isListView && !_vm.isMobile,"map-route":_vm.mapUrl,"schema-code":_vm.schemaCode,"url-open-new-window":_vm.urlOpenNewWindow}},'component',card,false))],1)}),0):_vm._e()]),_vm._t("default"),_c('div',{staticClass:"container d-flex flex-column row-gap-6"},[(!_vm.isLoading
        && _vm.paginatedItems
        && _vm.paginatedItems.length > (_vm.isDesktop ? (_vm.cardType === 'article' ? 6 : 8) : _vm.breakpoint.value === 'lg' ? 6 : 4)
        && !_vm.isCalendarView)?_c('div',{staticClass:"row row-gap-8"},_vm._l((_vm.paginatedItems
          .slice((_vm.isDesktop ? (_vm.cardType === 'article' ? 6 : 8) : _vm.breakpoint.value === 'lg' ? 6 : 4), _vm.paginatedItems.length)),function(card,idx){return _c('div',{key:card.uid,class:_vm.getClass(idx, _vm.paginatedItems.length)},[_c(_vm.WidgetCardMapping[_vm.cardType],_vm._b({tag:"component",attrs:{"card-route":_vm.cardRoute,"connection-modal-id":_vm.guuid.uuidv4(),"display-expiration-badge":_vm.displayExpirationBadge,"display-extra-properties":_vm.displayExtraProperties,"display-featured-border":_vm.displayFeaturedBorder,"display-hall":_vm.displayHall,"display-interested-badge":_vm.displayInterestedBadge,"display-live-badge":_vm.displayLiveBadge,"display-recommended-badge":_vm.displayRecommendedBadge,"display-speakers":_vm.displaySpeakers,"horizontal-display":_vm.isListView && !_vm.isMobile,"map-route":_vm.mapUrl,"schema-code":_vm.schemaCode}},'component',card,false))],1)}),0):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-40 py-md-80 p-xl-6"},[_c('loading-spinner-component')],1)]):_vm._e(),(_vm.localCount > 0 && _vm.itemsPerPage > 0 && !_vm.isLoading && _vm.usePagination)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 border-top border-neutral-n-4-line pt-3"},[_c('pagination-component',{attrs:{"current-page":_vm.page,"per-page":_vm.itemsPerPage,"total-row":_vm.localCount},on:{"on-page-change":_vm.onPageChanged}})],1)]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }